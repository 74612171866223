const QUERIES = {
  USERS_LIST: 'users',
  ROLES: 'roles',
  CLIENTS_LIST: 'clients',
  INTERVENTIONS_LIST: 'interventions',
  INVOICES_LIST: 'invoices',
  PULICITE_LIST: 'advertisement',
  INVOICE_LIST:"Innvoice",
  MAINTENANCE_PLANNING_LIST:"maintenancnePlanning"

}

export { QUERIES }
