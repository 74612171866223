import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import dataAxios from './dataAxios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return dataAxios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstName: string,
  lastName: string,
  address: {city: string; street: string},
  phoneNumber: string,
  phoneNumber2: string,
  status: string,
  participantType: string,
  secteur?: string,
  raisonSociale?: string,
  matriculeFiscale?: string,
  personToContact?: string,
  personToContactFunction?: string,
  personToContactPhone?: string,
  personToContactEmail?: string
) {
  return dataAxios.post(REGISTER_URL, {
    email,
    firstName: firstName,
    lastName: lastName,
    address,
    phoneNumber,
    phoneNumber2,
    status,
    participantType,
    secteur,
    raisonSociale,
    matriculeFiscale,
    personToContact,
    personToContactFunction,
    personToContactPhone,
    personToContactEmail,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return dataAxios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return dataAxios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    access_token: token,
  })
}
