import { FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {getAll} from '../../../../services/common_requests'
import {
  initialQueryState,
  PaginationState,
  QUERIES,
  WithChildren,
} from '../../../../../_dashboard/helpers'
import {
  TrackingProject,
  createITrackingProjectResponseContext,
  initialTrackingProjectQueryResponse,
} from './_models'
import {useQueryRequest} from './QueryRequestProvider'

const QueryTrackingProjectResponseContext = createITrackingProjectResponseContext<TrackingProject>(
  initialTrackingProjectQueryResponse
)

const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<any>(state)
  const updatedQuery = useMemo(() => state, [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching: isFetchingInterventions,
    refetch: refetchData,
    data: dataResponse,
  } = useQuery(
    `${QUERIES.INVOICE_LIST}-${JSON.stringify(query)}`,
    () => getAll(query, '/tracking/user'),
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryTrackingProjectResponseContext.Provider
      value={{
        isLoading: isFetchingInterventions,
        refetchData,
        dataResponse,
        query,
      }}
    >
      {children}
    </QueryTrackingProjectResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryTrackingProjectResponseContext)
const useQueryResponseData = () => {
  const {dataResponse} = useQueryResponse()
  return dataResponse?.data || []
}
const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {dataResponse} = useQueryResponse()
  if (!dataResponse?.payload?.pagination) {
    return defaultPaginationState
  }

  return dataResponse.payload.pagination
}
const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
