/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { ID, KTCardBody, KTSVG, toAbsoluteUrl } from '../../../helpers'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { getOneById } from '../../../../app/services/common_requests'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

type Props = {
  className: string
}

const ListsWidget2: React.FC<Props> = ({ className }) => {
  let { id } = useParams()
  const [devis, setDevis] = useState<any>(null);
  useEffect(() => {

    getOneById(id as ID, '/devisProvider/provider/applications').then((data) => {
      setDevis(data[0]);
    })



    return () => {
      //dispatch({type: actionTypes.GET_ONE_USER_SUBSCRIPTION, payload: {}})
    }

  }, [id])
  const intl = useIntl()
  console.log(devis,"deviiiiiiiiiiiis")
  return (
    // <div className={`card ${className}`}>
    //   {/* begin::Header */}
    //   <div className='card-header border-0'>
    //     <h3 className='card-title fw-bold text-dark'>Detail de devis</h3>
    //     <div className='card-toolbar'>
    //       {/* begin::Menu */}
    //       <button
    //         type='button'
    //         className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
    //         data-kt-menu-trigger='click'
    //         data-kt-menu-placement='bottom-end'
    //         data-kt-menu-flip='top-end'
    //       >
    //         <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
    //       </button>
    //       <Dropdown1 />
    //       {/* end::Menu */}
    //     </div>
    //   </div>
    //   {/* end::Header */}
    //   {/* begin::Body */}
    //   <div className='card-body pt-2'>
    //     {/* begin::Item */}
    //     <div className='d-flex align-items-center mb-7'>
    //       {/* begin::Avatar */}
    //       {/* <div className='symbol symbol-50px me-5'>
    //         <img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} className='' alt='' />
    //       </div> */}
    //       {/* end::Avatar */}
    //       {/* begin::Text */}
    //       <div className='flex-grow-1'>
    //         <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
    //          {devis?.reference}
    //         </a>

    //         <span className='text-muted d-block fw-semibold'>{devis?.createDate}</span>
    //       </div>
    //       {/* end::Text */}
    //     </div>
    //     {/* end::Item */}
    //     {/* begin::Item */}

    //     {/* end::Item */}

    //   </div>
    //   {/* end::Body */}
    // </div>
    <KTCardBody className='py-0' >
      <div className='table-responsive' style={{ marginBottom: "3rem" }}>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'

        >
          <thead >
            <tr className='text-start text-gray-500 fw-bolder fs-7 text-uppercase gs-0'>
              <td> {intl.formatMessage({ id: 'TABLE.REFRENCE' })} </td>
              <td> Date </td>
              <td>{intl.formatMessage({ id: 'TABLE.FILE.CLIENT' })}</td>
              <td>{intl.formatMessage({ id: 'TABLE.FILE.PROVIDER' })}</td>

            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold fs-6'>
            <td> {`${devis?.reference}`}</td>
            <td> {`${devis?.applicationDate}`}</td>
            <td>
              {devis?.devisFile && (
                <a href={process.env.REACT_APP_API_URL_IMG + devis?.devisFile.slice(1)} download className='btn btn-sm btn-light-primary fw-bolder'>
                  <span className='svg-icon svg-icon-primary svg-icon-2x me-1'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                      <path d='M18.0833 5.99999H5.91667C5.30618 5.99999 4.91667 6.3895 4.91667 6.99999V17C4.91667 17.6105 5.30618 18 5.91667 18H18.0833C18.6938 18 19.0833 17.6105 19.0833 17V6.99999C19.0833 6.3895 18.6938 5.99999 18.0833 5.99999ZM18.0833 17H5.91667V7.99999H18.0833V17ZM12.0001 14.5858L15.2929 11.293C15.6834 10.9025 15.6834 10.2694 15.2929 9.87887C14.9024 9.48835 14.2693 9.48835 13.8788 9.87887L12.7071 11.0506V5.99999C12.7071 5.3895 12.3176 4.99999 11.7071 4.99999C11.0966 4.99999 10.7071 5.3895 10.7071 5.99999V11.0506L9.53544 9.87887C9.14492 9.48835 8.5118 9.48835 8.12128 9.87887C7.73077 10.2694 7.73077 10.9025 8.12128 11.293L11.4141 14.5858C11.8046 14.9763 12.4377 14.9763 12.8282 14.5858C13.2187 14.1953 13.2187 13.5622 12.8282 13.1717L12.0001 14.0001L12.0001 14.5858Z' fill='currentColor' />
                    </svg>
                  </span>
                  Télécharger
                </a>
              )}
            </td>

            <td>
              {devis?.devisProviderFile && (
                <a href={process.env.REACT_APP_API_URL_IMG + devis?.devisProviderFile?.slice(1)} download className='btn btn-sm btn-light-primary fw-bolder'>
                  <span className='svg-icon svg-icon-primary svg-icon-2x me-1'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                      <path d='M18.0833 5.99999H5.91667C5.30618 5.99999 4.91667 6.3895 4.91667 6.99999V17C4.91667 17.6105 5.30618 18 5.91667 18H18.0833C18.6938 18 19.0833 17.6105 19.0833 17V6.99999C19.0833 6.3895 18.6938 5.99999 18.0833 5.99999ZM18.0833 17H5.91667V7.99999H18.0833V17ZM12.0001 14.5858L15.2929 11.293C15.6834 10.9025 15.6834 10.2694 15.2929 9.87887C14.9024 9.48835 14.2693 9.48835 13.8788 9.87887L12.7071 11.0506V5.99999C12.7071 5.3895 12.3176 4.99999 11.7071 4.99999C11.0966 4.99999 10.7071 5.3895 10.7071 5.99999V11.0506L9.53544 9.87887C9.14492 9.48835 8.5118 9.48835 8.12128 9.87887C7.73077 10.2694 7.73077 10.9025 8.12128 11.293L11.4141 14.5858C11.8046 14.9763 12.4377 14.9763 12.8282 14.5858C13.2187 14.1953 13.2187 13.5622 12.8282 13.1717L12.0001 14.0001L12.0001 14.5858Z' fill='currentColor' />
                    </svg>
                  </span>
                  Télécharger
                </a>
              )}
            </td>

          </tbody>
        </table>
      </div>
    </KTCardBody>
  )
}

export { ListsWidget2 }
