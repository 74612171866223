import { PaginationState } from "../../../../../_dashboard/helpers"
import { initialQueryState } from '../../../../../_dashboard/helpers/crud-helper/models';
import { useQueryResponse } from "./QueryResponseProvider";

export const useQueryResponsePagination = () => {
    const defaultPaginationState: PaginationState = {
      links: [],
      ...initialQueryState,
    }
    const {dataResponse} = useQueryResponse()
  
    if (!dataResponse || !dataResponse.payload || !dataResponse.payload?.pagination) {
      return defaultPaginationState
    }
    let pagination = {...dataResponse?.payload?.pagination}
    pagination.links = []
    if (pagination) {
      for (let page = 0; page < pagination?.totalPages; page++) {
        const isActive = page === pagination?.currentPage
        const url = isActive ? null : `?page=${page}` // Assuming query string for pagination
        pagination?.links?.push({
          label: `${page + 1}`,
          active: isActive,
          url,
          page,
        })
      }
  
      // Add "prev" button if not on the first page
      if (pagination.currentPage > 0) {
        pagination.links.unshift({
          label: "Prev",
          active: false,
          url: `?page=${pagination.currentPage - 1}`,
          page: pagination.currentPage - 1,
        })
      }
  console.log(   pagination.links,"   pagination.links   pagination.links")
      // Add "next" button if not on the last page
      if (pagination.currentPage < pagination.totalPages - 1) {
        pagination.links.push({
          label: "Next",
          active: false,
          url: `?page=${pagination.currentPage + 1}`,
          page: pagination.currentPage + 1,
        })
      }
    }
  
    return pagination
  }
