import {  KTCard, KTCardBody } from "../../../../../_dashboard/helpers"
import { ListsWidget2 } from "../../../../../_dashboard/partials/widgets"


type Props = {
    className: string
  }
  const DevisInfo: React.FC<Props> = ({className}) => {
    return (
      <>
    
        <div style={{marginTop:"-1.5rem"}}>
       <div className='py-3 mx-8 text-muted fs-7'>  
       {/* {intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTION_DETAILS'})} */}
       
       </div>
       
        <KTCard>
        
    <ListsWidget2 className=""/>
      </KTCard>
      </div>
      </>
    )
  }
  export default DevisInfo
  