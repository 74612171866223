/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import { getAllpubl } from '../../../../../app/services/common_requests';
import { Advertisement } from '../../../../../app/pages/advertisements-management/advertisement-list/core/_models';
import { getYouTubeVideoId } from '../../../../helpers';

type Props = {
  className: string;
};

const EngageWidget10 = ({ className }: Props) => {
  const [ads, setAds] = useState<Advertisement[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetchAds();
  }, []);

  const fetchAds = async () => {
    try {
      const response = await getAllpubl('/auth/ads');
      const adsData: Advertisement[] = response.data;
      setAds(adsData);
    } catch (error) {
      console.error('Error fetching ads:', error);
    }
  };
  const handleVideoEnd = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % ads.length);
    
  };

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-4 text-primary me-2'>Gallerie de publicité</span>
          </div>
        </div>
      </div>
      <div className='card-body d-flex flex-column justify-content-between mt-0 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'>
        <div>
          <Carousel autoplay style={{ marginBottom: '1.7rem' }}>
            {ads.map((ad, index) => {
              if (!ad || !ad.adsFile) {
                return null;
              }

              const imageUrl = ad.typeFile === 'TYPE_FILE_IMAGE' ? process.env.REACT_APP_API_URL_IMG + ad.adsFile.slice(1) : "";
              const youtubeID = ad.typeFile === 'TYPE_FILE_URL' ? getYouTubeVideoId(ad.adsFile) : "";
              const isYouTubeVideo = ad.typeFile === 'TYPE_FILE_URL';
              const isVideo = ad.typeFile === 'TYPE_FILE_VIDEO';

              return isYouTubeVideo ? (
                <div key={index}>
                  <div className='d-flex align-items-center flex-grow-1'>
                    <iframe
                      width='100%'
                      height='400px'
                      src={`https://www.youtube.com/embed/${youtubeID}`}
                      allow='autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                      title='Embedded YouTube Video'
                    ></iframe>
                  </div>
                </div>
              ) : isVideo ? (
                <div key={index}>
                  <div className='d-flex align-items-center flex-grow-1'>
                    <video width='100%' height='400px' controls autoPlay muted onEnded={handleVideoEnd}>
                      <source src={process.env.REACT_APP_API_URL_IMG + ad.adsFile.slice(1)} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              ) : (
                imageUrl && (
                  <div key={index}>
                    <img
                      className='mx-auto w-50'
                      src={imageUrl}
                      alt={`adsfile ${index + 1}`}
                    />
                  </div>
                )
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export { EngageWidget10 };
