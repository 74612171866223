import {PageLink, PageTitle} from '../../../../../_dashboard/layout/core'
import {FC, useState} from 'react'
import DevisInfo from './devisInfo'

const SubscriptionsDetailsTabs: FC = () => {
  const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Tableau de bord',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <div>
      <PageTitle breadcrumbs={profileBreadCrumbs}>
        {/* {intl.formatMessage({id: 'GORELLA.COMMON.SUBSCRIPTION_DETAILS'})} */}
        Details du devis
      </PageTitle>

      <DevisInfo className='p-5' />
    </div>
  )
}

export default SubscriptionsDetailsTabs
