// @ts-nocheck
import {Column} from 'react-table'

import {TrackingProjectCustomHeader} from './TrackingProjectCustomHeader'
import {User} from '../../core/_models'
import {FormattedMessage} from 'react-intl'
import {CustomAdvertissmentStatus} from '../../../../advertisements-management/advertisement-list/table/columns/customStatus'
import {TrackingProjectMainHeader} from './TrackingProjectMainHeader'

const TrackingProjectColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <TrackingProjectCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ADMIN.TABLE.NUMBER.DEVIS' />}
        className='min-w-20px bg-gray'
      />
    ),
    accessor: 'nbreDevis',
  },
  {
    Header: (props) => (
      <TrackingProjectCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ADMIN.TABLE.REFERENCE' />}
        className='min-w-125px'
      />
    ),
    id: 'reference',
    accessor: 'reference',
  },
  {
    Header: (props) => (
      <TrackingProjectCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ADMIN.TABLE.ETUDE' />}
        className='min-w-125px'
      />
    ),
    id: 'etude',
    Cell: ({...props}) => <CustomAdvertissmentStatus status={props.data[props.row.index].etude} />,
  },
  {
    Header: (props) => (
      <TrackingProjectCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ADMIN.TABLE.STEG' />}
        className='min-w-125px'
      />
    ),
    id: 'steg',
    Cell: ({...props}) => <CustomAdvertissmentStatus status={props.data[props.row.index].steg} />,
  },
  {
    Header: (props) => (
      <TrackingProjectCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ADMIN.TABLE.ANME' />}
        className='min-w-125px'
      />
    ),
    id: 'anme',
    Cell: ({...props}) => <CustomAdvertissmentStatus status={props.data[props.row.index].anme} />,
  },
  {
    Header: (props) => (
      <TrackingProjectCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ADMIN.TABLE.MINISTRE' />}
        className='min-w-125px'
      />
    ),
    id: 'ministre',
    Cell: ({...props}) => (
      <CustomAdvertissmentStatus status={props.data[props.row.index].ministre} />
    ),
  },
  {
    Header: (props) => (
      <TrackingProjectCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ADMIN.TABLE.DELIVERY' />}
        className='min-w-125px'
      />
    ),
    id: 'livraison',
    Cell: ({...props}) => (
      <CustomAdvertissmentStatus status={props.data[props.row.index].livraison} />
    ),
  },

    {
      Header: (props) => (
        <TrackingProjectCustomHeader
          tableProps={props}
          title={<FormattedMessage id='ADMIN.TABLE.STRUCTURE' />}
          className='min-w-125px'
        />
      ),
      id: 'structure',
      Cell: ({...props}) => (
        <CustomAdvertissmentStatus status={props.data[props.row.index].structure} />
      ),
    },
    {
      Header: (props) => (
        <TrackingProjectCustomHeader
          tableProps={props}
          title={<FormattedMessage id='ADMIN.TABLE.PANNEL' />}
          className='min-w-125px '
        />
      ),
      id: 'panneaux',
      Cell: ({...props}) => (
        <CustomAdvertissmentStatus status={props.data[props.row.index].panneaux} />
      ),
    },
    {
      Header: (props) => (
        <TrackingProjectCustomHeader
          tableProps={props}
          title={<FormattedMessage id='ADMIN.TABLE.BRANCHEMENT' />}
          className='min-w-125px'
        />
      ),
      id: 'branchement',
      Cell: ({...props}) => (
        <CustomAdvertissmentStatus status={props.data[props.row.index].branchement} />
      ),
    },

]

export {TrackingProjectColumns}
