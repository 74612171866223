// dashboard/DashboardWrapper.tsx
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_dashboard/layout/core'
import {KTCard} from '../../../_dashboard/helpers'
import {EngageWidget10, ListsWidget1} from '../../../_dashboard/partials/widgets'
import {QueryRequestProvider} from '../trackingProject-management/trackingProject-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../trackingProject-management/trackingProject-list/core/QueryResponseProvider'
import {ListViewProvider} from '../trackingProject-management/trackingProject-list/core/ListViewProvider'
import {TrackingProjectListTable} from '../trackingProject-management/trackingProject-list/table/TrackingProjectTable'
import {Link} from 'react-router-dom'

const DashboardPage: FC = () => {
  const role = localStorage.getItem('role')

  return role === 'ROLE_CLIENT' ? (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <KTCard>
              <div
                className='fs-3 mb-5 pe-4 text-primary mt-5 fw-bold'
                style={{marginLeft: '2rem'}}
              >
                <Link
                  to='/invoice-management/invoice'
                  className='fs-3 mb-5 pe-4 text-primary mt-5 fw-bold'
                >
                  Suivi des projets
                </Link>
              </div>
              <TrackingProjectListTable />
            </KTCard>{' '}
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
      <div style={{margin: '20px 0'}} />
      <KTCard>
        <ListsWidget1 className='' />
      </KTCard>
    </>
  ) : (
    <KTCard>
      {' '}
      <div className='row g-5 g-xl-8'>
        <div className='col-xxl-6' style={{height: '100%'}}>
          <EngageWidget10 className='h-md-100' />
        </div>
      </div>{' '}
    </KTCard>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
