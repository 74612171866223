import { FC } from 'react'

type Props = {
  status?: any
  expiryDate?: string // Ajoutez le type de la date d'expiration
}

const CustomAdvertissmentStatus: FC<Props> = ({ status, expiryDate }) => {
  // Vérifiez si la date d'expiration est définie
  const isExpired = expiryDate && new Date(expiryDate) < new Date()

  // Déterminez le statut en fonction de la date d'expiration
  let displayStatus = status
  if (displayStatus === 'PENDING'||displayStatus === '3000'||displayStatus === false) {
    displayStatus = { code: '10', name: 'En attente' }
  } else if (displayStatus === '2000' || displayStatus === true) {
    displayStatus = { code: '100', name: 'Traité' }
  } else {
    displayStatus = { code: '400', name: 'Non valide' }
  }

  return (
    <>
      {' '}
      <span
        style={{ width: 'auto', padding: '8px 15px 8px 14px', fontSize: '12.75px' }}
        className={`badge ${displayStatus.code === '10'
            ? 'badge-light-warning'
            : displayStatus.code === '20'
              ? 'badge-light-info'
              : displayStatus.code === '100'
                ? 'badge-light-success'
                : displayStatus.code === '30'
                  ? 'badge-light-primary'
                  : displayStatus.code === '400'
                    ? 'badge-light-danger'
                    : 'badge-light-warning'
          }`}
      >
        {displayStatus.name}
      </span>
    </>
  )
}

export { CustomAdvertissmentStatus }
