import { createContext } from 'react';
import { ID, Response } from '../../../../../_dashboard/helpers'
import { Client } from '../../../clients-management/clients-list/core/_models';
export type TrackingProject = {
  id?: ID
  createDate?: string;
  createdBy?: {
    _id?: string;
    firstName?: string;
    lastName?: string;
  };
  description?: string;
  reference?:string;
  invoiceFile?:string| null;
  projectId?: string;
  interventionDate?: string;
  client?: Client; 
 

  updateDate?: string;
  _id?: string;
};

export type TrackingProjectQueryResponse = Response<Array<TrackingProject>>

export const initialTrackingProject: TrackingProject = {
  reference: '',
  invoiceFile: null, // Initialize as null or empty string
  createDate: ''
};
export type QueryResponseITrackingProjectContextProps<T> = {
  dataResponse?: Response<Array<T>> | undefined
  refetchData: () => void
  isLoading: boolean
  query: any
}
export function createITrackingProjectResponseContext<T>(initialState: QueryResponseITrackingProjectContextProps<T>) {
  return createContext(initialState)
}
export const initialTrackingProjectQueryResponse = { refetchData: () => {}, isLoading: false, query: '' }

