import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_dashboard/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_dashboard/assets/ts/_utils'
import {WithChildren} from '../../_dashboard/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import SubscriptionsDetailsTabs from '../pages/devisclient-management/devisclient-list/Devis-info/tab'
import {Landing} from '../../landing-page/LandingPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UsersPage = lazy(() => import('../pages/user-management/UsersPage'))
  const InterventionPage = lazy(() => import('../pages/reclamtions-management/ReclamtionsPage'))
  const ClientPage = lazy(() => import('../pages/clients-management/ClientsPage'))
  const DevisClientPage = lazy(() => import('../pages/devisclient-management/devisclientPage'))
  const InvoicePage = lazy(() => import('../pages/invoice-management/InvoicesPage'))
  const ProductionPage = lazy(() => import('../pages/production-management/productionPage'))
  const TrackingProjectPage = lazy(
    () => import('../pages/trackingProject-management/TrackingProjectPage')
  )
  const Devisprovider = lazy(() => import('../pages/devisprovider-management/devisproviderPage'))
  const DevisPage = lazy(() => import('../pages/devis-managment/devisPage'))
  const GalleriePage = lazy(() => import('../pages/galeries-management/galeriePage'))
  const AdvertisementsPage = lazy(
    () => import('../pages/advertisements-management/AdvertisementsPage')
  )
  const MaintenancePage = lazy(
    () => import('../pages/maintenancePlanning-management/maintenancePlanning-Page')
  )
  return (
    <Routes>
      <Route path='menu-test' element={<MenuTestPage />} />
      <Route path='/home' element={<Landing />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='client-management/*'
          element={
            <SuspensedView>
              <ClientPage />
            </SuspensedView>
          }
        />
        <Route
          path='clientdevis-management/*'
          element={
            <SuspensedView>
              <DevisClientPage />
            </SuspensedView>
          }
        />
        <Route
          path='devisprovider-management/*'
          element={
            <SuspensedView>
              <Devisprovider />
            </SuspensedView>
          }
        />
        (
        <Route
          path='devis-management/*'
          element={
            <SuspensedView>
              <DevisPage />
            </SuspensedView>
          }
        />
        )
        <Route
          path='reclamation-management/*'
          element={
            <SuspensedView>
              <InterventionPage />
            </SuspensedView>
          }
        />
        <Route
          path='invoice-management/*'
          element={
            <SuspensedView>
              <InvoicePage />
            </SuspensedView>
          }
        />
        <Route
          path='production-management/*'
          element={
            <SuspensedView>
              <ProductionPage />
            </SuspensedView>
          }
        />
        <Route
          path='tracking-project-management/*'
          element={
            <SuspensedView>
              <TrackingProjectPage />
            </SuspensedView>
          }
        />
        <Route path='upload-report/:id' element={<SubscriptionsDetailsTabs />} />
        <Route
          path='galeries-management/*'
          element={
            <SuspensedView>
              <GalleriePage />
            </SuspensedView>
          }
        />
        <Route
          path='advertisements-management/*'
          element={
            <SuspensedView>
              <AdvertisementsPage />
            </SuspensedView>
          }
        />
        <Route
          path='maintenance-management/*'
          element={
            <SuspensedView>
              <MaintenancePage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
