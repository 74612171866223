/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {ID, KTCardBody} from '../../../helpers'
import {getAll} from '../../../../app/services/common_requests'
import {Link, useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {CustomAdvertissmentStatus} from '../../../../app/pages/advertisements-management/advertisement-list/table/columns/customStatus'

type Props = {
  className: string
}

const ListsWidget1: React.FC<Props> = ({className}) => {
  let {id} = useParams()
  const [factures, setFactures] = useState<any[]>([])
  const intl = useIntl()

  useEffect(() => {
    getAll(id as ID, '/facture/user').then((data) => {
      setFactures(data.data)
    })
  }, [id])

  const latestFactures = factures.slice(-2)

  const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string) => {
    event.preventDefault()
    downloadImage(url)
  }

  const downloadImage = (url: string) => {
    fetch(url, {
      mode: 'no-cors',
    })
      .then((response: Response) => response.blob())
      .then((blob: Blob) => {
        const blobUrl = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.download = url.substring(url.lastIndexOf('/') + 1)
        a.href = blobUrl
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
  }
  return (
    <KTCardBody className='py-0'>
      <div className='table-responsive' style={{marginBottom: '3rem'}}>
        <div className='fs-3 mb-5 pe-4 text-primary mt-5 fw-bold'>
          <Link
            to='/invoice-management/invoice'
            className='fs-3 mb-5 pe-4 text-primary mt-5 fw-bold'
          >
            Liste de Facture
          </Link>
        </div>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-gray-500 fw-bolder fs-7 text-uppercase gs-0'>
              <th>{intl.formatMessage({id: 'TABLE.SUBSCRIPTION.REFERENCE'})}</th>
              <th>{intl.formatMessage({id: 'ADMIN.COMMON.YEAR'})}</th>
              <th>{intl.formatMessage({id: 'ADMIN.COMMON.MONTH'})}</th>
              <th>{intl.formatMessage({id: 'TABLE.TYPE'})}</th>
              <th>{intl.formatMessage({id: 'ADMIN.COMMON.NUMBER.INVOICE'})}</th>
              <th>{intl.formatMessage({id: 'ADMIN.COMMON.AMOUNT'})}</th>
              <th>{intl.formatMessage({id: 'TABLE.FILE'})}</th>
              <th>{intl.formatMessage({id: 'ADMIN.COMMON.STATUS'})}</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold fs-6'>
            {Array.isArray(latestFactures) &&
              latestFactures.map((facture, index) => (
                <tr key={index}>
                  <td>{facture.reference}</td>
                  <td>{facture.year}</td>
                  <td>{facture.month}</td>
                  <td>{facture.type}</td>
                  <td>{facture.number}</td>
                  <td>{facture.amount}</td>
                  <td>
                    {facture.file && (
                      <a
                        href={`${process.env.REACT_APP_API_URL_IMG}${facture.file.slice(1)}`}
                        onClick={(e) =>
                          handleDownload(
                            e,
                            `${process.env.REACT_APP_API_URL_IMG}${facture.file.slice(1)}`
                          )
                        }
                        className='btn btn-sm btn-light-primary fw-bolder'
                      >
                        Télécharger
                      </a>
                    )}
                  </td>
                  <td>
                    <CustomAdvertissmentStatus status={facture.status} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </KTCardBody>
  )
}

export {ListsWidget1}
