/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {register} from '../core/_requests'

import {PasswordMeterComponent} from '../../../../_dashboard/assets/ts/components'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

const initialValues = {
  acceptTerms: false,
  email: '',
  createDate: '',
  address: {
    city: '',
    street: '',
  },
  firstName: '',
  lastName: '',
  phoneNumber: '',
  phoneNumber2: '',
  status: 'ACTIVE',
  updateDate: '',
  _id: '',
  participantType: '',
  secteur: '',
  raisonSociale: '',
  matriculeFiscale: '',
  personToContact: '',
  personToContactFunction: '',
  personToContactPhone: '',
  personToContactEmail: '',
}


const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 symboles')
    .max(50, 'Maximum 50 symboles')
    .required('Le prénom est requis'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symboles')
    .max(50, 'Maximum 50 symboles')
    .required('Le nom de famille est requis'),
  email: Yup.string()
    .email("Format d'email incorrect")
    .min(3, 'Minimum 3 symboles')
    .max(50, 'Maximum 50 symboles')
    .required("L'email est requis"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Le numéro de téléphone ne doit contenir que des chiffres')
    .min(3, 'Minimum 3 symboles')
    .max(50, 'Maximum 50 symboles')
    .required('Le numéro de téléphone est requis'),
  phoneNumber2: Yup.string()
    .matches(/^[0-9]+$/, 'Le numéro de téléphone ne doit contenir que des chiffres')
    .min(3, 'Minimum 3 symboles')
    .max(50, 'Maximum 50 symboles'),
  address: Yup.object().shape({
    city: Yup.string()
      .min(3, 'Minimum 3 symboles')
      .max(50, 'Maximum 50 symboles')
      .required('La ville est requise'),
    street: Yup.string()
      .min(3, 'Minimum 3 symboles')
      .max(50, 'Maximum 50 symboles')
      .required('La rue est requise'),
  }),
  participantType: Yup.string().required('Le type de participant est requis'),
  secteur: Yup.string().when('participantType', {
    is: (val: string) => val === 'MORALE' || val === 'PROVIDERS',
    then: Yup.string().required('Le secteur est requis'),
  }),
  raisonSociale: Yup.string().when('participantType', {
    is: (val: string) => val === 'MORALE' || val === 'PROVIDERS',
    then: Yup.string().required('La raison sociale est requise'),
  }),
  matriculeFiscale: Yup.string().when('participantType', {
    is: (val: string) => val === 'MORALE' || val === 'PROVIDERS',
    then: Yup.string().required('Le matricule fiscale est requis'),
  }),
  personToContact: Yup.string().when('participantType', {
    is: (val: string) => val === 'MORALE' || val === 'PROVIDERS',
    then: Yup.string().required('La personne à contacter est requise'),
  }),
  personToContactFunction: Yup.string().when('participantType', {
    is: (val: string) => val === 'MORALE' || val === 'PROVIDERS',
    then: Yup.string().required('La fonction de la personne à contacter est requise'),
  }),
  personToContactPhone: Yup.string().when('participantType', {
    is: (val: string) => val === 'MORALE' || val === 'PROVIDERS',
    then: Yup.string().required('Le numéro de téléphone de la personne à contacter est requis'),
  }),
  personToContactEmail: Yup.string().when('participantType', {
    is: (val: string) => val === 'MORALE' || val === 'PROVIDERS',
    then: Yup.string().required("L'email de la personne à contacter est requis"),
  }),
})

export function Registration() {
  const navigate = useNavigate();
  const intl = useIntl()
  const participantTypes = [
    {_id: 'MORALE', name: 'Entreprise'},
    {_id: 'PHYSIQUE', name: 'Personne individuelle'},
    {_id: 'PROVIDERS', name: 'Fournisseur'},
  ]
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
  
      try {
        const response = await register(
          values.email,
          values.firstName,
          values.lastName,
          values.address,
          values.phoneNumber,
          values.phoneNumber2,
          values.status,
          values.participantType,
          values.secteur,
          values.raisonSociale,
          values.matriculeFiscale,
          values.personToContact,
          values.personToContactFunction,
          values.personToContactPhone,
          values.personToContactEmail
        );
  
        if (response.data.status=201) {
          navigate('/auth');
          setLoading(false)

       } else {
          setStatus('les details registration sont incorrect')

        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('les details registration sont incorrect')
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  
  

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework mb-5'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Inscription</h1>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      <div className='d-flex'>
        <div className='fv-row mb-8 me-14'>
          <label className='form-label fw-bolder text-dark fs-6'>Prénom </label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstName')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.firstName && formik.errors.firstName,
              },
              {
                'is-valid': formik.touched.firstName && !formik.errors.firstName,
              }
            )}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Nom de famille</label>
          <input
            placeholder='Last name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastName')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.lastName && formik.errors.lastName,
              },
              {
                'is-valid': formik.touched.lastName && !formik.errors.lastName,
              }
            )}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastName}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      
        <div className='fv-row mb-8 '>
          <label className='form-label fw-bolder text-dark fs-6'>E-mail</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex'>
        <div className='fv-row mb-8 me-14'>
          <label className='form-label fw-bolder text-dark fs-6'>Numéro de téléphone</label>
          <input
            placeholder='Numéro de téléphone'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('phoneNumber')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber},
              {
                'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
              }
            )}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phoneNumber}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Numéro de téléphone 2</label>
          <input
            placeholder='Numéro de téléphone'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('phoneNumber2')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.phoneNumber2 && formik.errors.phoneNumber2},
              {
                'is-valid': formik.touched.phoneNumber2 && !formik.errors.phoneNumber2,
              }
            )}
          />
          {formik.touched.phoneNumber2 && formik.errors.phoneNumber2 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phoneNumber2}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='d-flex'>
        <div className='fv-row mb-8 me-14'>
          <label className='form-label fw-bolder text-dark fs-6'>ville</label>
          <input
            placeholder='ville'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('address.city')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.address?.city && formik.errors.address?.city},
              {
                'is-valid': formik.touched.address?.city && !formik.errors.address?.city,
              }
            )}
          />
          {formik.touched.address?.city && formik.errors.address?.city && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.address?.city}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Adresse</label>
          <input
            placeholder='Adresse'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('address.street')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.address?.street && formik.errors.address?.street},
              {
                'is-valid': formik.touched.address?.street && !formik.errors.address?.street,
              }
            )}
          />
          {formik.touched.address?.street && formik.errors.address?.street && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.address?.street}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row mb-8 '>
        <label className='form-label fw-bolder text-dark fs-6'>Participant Type</label>
        <select
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.participantType && formik.errors.participantType},
            {
              'is-valid': formik.touched.participantType && !formik.errors.participantType,
            }
          )}
          {...formik.getFieldProps('participantType')}
        >
          <option value='' label='Select participant type' />
          {participantTypes.map((type) => (
            <option key={type._id} value={type._id}>
              {type.name}
            </option>
          ))}
        </select>
        {formik.touched.participantType && formik.errors.participantType && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.participantType}</span>
            </div>
          </div>
        )}
      </div>

      {(formik.values.participantType === 'MORALE' ||
        formik.values.participantType === 'PROVIDERS') && (
        <>
          <div className='d-flex'>
            <div className='fv-row mb-8 me-14'>
              <label className='form-label fw-bolder text-dark fs-6'>Secteur</label>
              <input
                placeholder='Secteur'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('secteur')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.secteur && formik.errors.secteur,
                  },
                  {
                    'is-valid': formik.touched.secteur && !formik.errors.secteur,
                  }
                )}
              />
              {formik.touched.secteur && formik.errors.secteur && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.secteur}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Raison Sociale</label>
              <input
                placeholder='Raison Sociale'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('raisonSociale')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.raisonSociale && formik.errors.raisonSociale,
                  },
                  {
                    'is-valid': formik.touched.raisonSociale && !formik.errors.raisonSociale,
                  }
                )}
              />
              {formik.touched.raisonSociale && formik.errors.raisonSociale && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.raisonSociale}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Matricule Fiscale</label>
            <input
              placeholder='Matricule Fiscale'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('matriculeFiscale')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.matriculeFiscale && formik.errors.matriculeFiscale,
                },
                {
                  'is-valid': formik.touched.matriculeFiscale && !formik.errors.matriculeFiscale,
                }
              )}
            />
            {formik.touched.matriculeFiscale && formik.errors.matriculeFiscale && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.matriculeFiscale}</span>
                </div>
              </div>
            )}
          </div>
          <div className='d-flex'>
            <div className='fv-row mb-8 me-14'>
              <label className='form-label fw-bolder text-dark fs-6'>Personne à contacter</label>
              <input
                placeholder='Personne à contacter'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('personToContact')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.personToContact && formik.errors.personToContact,
                  },
                  {
                    'is-valid': formik.touched.personToContact && !formik.errors.personToContact,
                  }
                )}
              />
              {formik.touched.personToContact && formik.errors.personToContact && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.personToContact}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'> Fonction</label>
              <input
                placeholder='Fonction'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('personToContactFunction')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.personToContactFunction &&
                      formik.errors.personToContactFunction,
                  },
                  {
                    'is-valid':
                      formik.touched.personToContactFunction &&
                      !formik.errors.personToContactFunction,
                  }
                )}
              />
              {formik.touched.personToContactFunction && formik.errors.personToContactFunction && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.personToContactFunction}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='d-flex'>
            <div className='fv-row mb-8 me-15'>
              <label className='form-label fw-bolder text-dark fs-6'>Téléphone</label>
              <input
                placeholder='Téléphone'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('personToContactPhone')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.personToContactPhone && formik.errors.personToContactPhone,
                  },
                  {
                    'is-valid':
                      formik.touched.personToContactPhone && !formik.errors.personToContactPhone,
                  }
                )}
              />
              {formik.touched.personToContactPhone && formik.errors.personToContactPhone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.personToContactPhone}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-8 '>
              <label className='form-label fw-bolder text-dark fs-6'>E-mail</label>
              <input
                placeholder='E-mail'
                type='email'
                autoComplete='off'
                {...formik.getFieldProps('personToContactEmail')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.personToContactEmail && formik.errors.personToContactEmail,
                  },
                  {
                    'is-valid':
                      formik.touched.personToContactEmail && !formik.errors.personToContactEmail,
                  }
                )}
              />
              {formik.touched.personToContactEmail && formik.errors.personToContactEmail && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.personToContactEmail}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/* <div className='fv-row mb-8'>
        <label className='form-check form-check-custom form-check-solid form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span className='form-check-label fw-bold text-gray-700 fs-6 ml-2'>
            I Agree the
            <Link to='/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div> */}

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_login_signup_form_submit_button'
          className='btn btn-primary'

          // disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
