import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../_dashboard/helpers'
import { CommonQueryResponse } from './common_types'
import dataAxios from '../modules/auth/core/dataAxios'

const API_URL = process.env.REACT_APP_API_URL || ''
const getAll = (query: any, URL: string): Promise<any> => {
  return dataAxios
    .get(`${API_URL + URL}`, { params: { ...query } })

    .then((d: any) => { return d.data })
}

const getAllClients = (URL: string): Promise<any> => {
  return dataAxios
    .get(`${API_URL + URL}`)
    .then((d: any) => {  return d.data })
}

const getAllCatgories = (URL: string): Promise<any> => {
  return dataAxios
    .get(`${API_URL + URL}`)
    .then((d: any) => {  return d.data })
}

const getAllpubl = (URL: string): Promise<any> => {
  return dataAxios
    .get(`${API_URL + URL}`)
    .then((d: any) => {  return d.data })
}

const getOneById = (id: ID, URL: String): Promise<any | undefined> => {
  return dataAxios
    .get(`${API_URL + URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}
const getMe = (): Promise<any> => {
  return dataAxios
    .get(`${API_URL}/clients/me`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const createOne = (user: any, URL: String): Promise<any | undefined> => {
  return dataAxios
    .post(API_URL + URL, user)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}

const updateOne = (user: any, URL: String): Promise<any | undefined> => {
  return dataAxios
    .patch(`${API_URL + URL}/${user._id}`, user)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const Postuler = (user: any, URL: String): Promise<any | undefined> => {
  return dataAxios
    .put(`${API_URL + URL}/apply-provider`, user)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const UpdatePassword = (user: any,URL: String): Promise<any | undefined> => {
  return dataAxios
    .put(`${API_URL + URL}`,user )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const updateOne1 = (user: any, URL: String): Promise<any | undefined> => {
  return dataAxios
    .patch(`${API_URL + URL}`, user)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const deleteOne = (userId: ID, URL: String): Promise<void> => {
  return dataAxios.delete(`${API_URL + URL}/${userId}`).then(() => { })
}

const deleteSelectedOne = (userIds: Array<ID>, URL: String): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${API_URL + URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export {UpdatePassword, Postuler,getAllpubl, updateOne1,getMe,getAllCatgories, getAllClients, getAll, deleteOne, deleteSelectedOne, getOneById, createOne, updateOne }
