/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()

  const {auth} = useAuth()
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/graphs/gra012.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}

      {/* <SidebarMenuItem
        to='/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <SidebarMenuItem
        to='/client-management'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Clients management'
        fontIcon='bi-layers'
      /> */}
      {auth?.data.role === 'ROLE_PROVIDER' && (
        <SidebarMenuItem
          to='/devis-management'
          icon='/media/icons/duotune/abstract/abs037.svg'
          title={intl.formatMessage({id: 'ADMIN.COMMON.LIST.DEVIS'})}
          fontIcon='bi-layers'
        />
      )}

      {/* {auth?.role === 'ROLE_CLIENT' && ( */}
      <SidebarMenuItem
        to='/advertisements-management'
        icon='/media/icons/duotune/social/soc007.svg'
        title={intl.formatMessage({id: 'ADMIN.PUBLICITE.LIST'})}
        fontIcon='bi-layers'
      />

      {auth?.data.role === 'ROLE_CLIENT' && (
        <>
          <SidebarMenuItem
            to='/clientdevis-management'
            icon='/media/icons/duotune/general/gen002.svg'
            title={intl.formatMessage({id: 'ADMIN.COMMON.DEVIS'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/maintenance-management'
            icon='/media/icons/duotune/ecommerce/ecm008.svg'
            title={intl.formatMessage({id: 'MENU.MAINTENANCE'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/invoice-management'
            icon='/media/icons/duotune/ecommerce/ecm008.svg'
            title={intl.formatMessage({id: 'ADMIN.INVOICE.LIST'})}
            fontIcon='bi-layers'
          />

          <SidebarMenuItem
            to='/production-management'
            icon='/media/icons/duotune/finance/fin007.svg'
            title={intl.formatMessage({id: 'ADMIN.PRODUCTION.LIST'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/tracking-project-management'
            icon='/media/icons/duotune/maps/map003.svg'
            title={intl.formatMessage({id: 'ADMIN.TRACKING.PROJECT.LIST'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/reclamation-management'
            icon='/media/icons/duotune/communication/com010.svg'
            title={intl.formatMessage({id: 'ADMIN.RECLAMTION.LIST'})}
            fontIcon='bi-layers'
          />
        </>
      )}

      <SidebarMenuItem
        to='/galeries-management'
        icon='/media/icons/duotune/general/gen006.svg'
        title={intl.formatMessage({id: 'GALLERIE'})}
        fontIcon='bi-layers'
      />
      {auth?.data.role === 'ROLE_PROVIDER' && (
        <SidebarMenuItem
          to='/devisprovider-management'
          icon='/media/icons/duotune/abstract/abs046.svg'
          title={intl.formatMessage({id: 'ADMIN.COMMON.LIST.APPLICATION'})}
          fontIcon='bi-layers'
        />
      )}
      <SidebarMenuItem
        to='crafted/pages/profile/overview'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({id: 'SETTINGS'})}
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
