import {FC, useState} from 'react'
import {KTCard, toAbsoluteUrl} from '../_dashboard/helpers'
import '../_dashboard/global.css'
import '../_dashboard/index.css'
import '../_dashboard/home-2.css'
import Slider from 'react-slick'
import {Carousel} from 'antd'
import React from 'react'
import {createOne} from '../app/services/common_requests'
import {ToastContainer, toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import 'react-toastify/dist/ReactToastify.css'
import SlideshowX from './SlideshowX'
import {EngageWidget10} from '../_dashboard/partials/widgets'

const Landing: FC = () => {
  const intl = useIntl()
  const contentStyle: React.CSSProperties = {
    margin: 0,
    color: '#fff',
    textAlign: 'center',
    // background: 'red',
    height: '10%!important',
    maxWidth: '100%',
  }
  const images = [
    {
      src: toAbsoluteUrl('/media/landingpage/image (1).png'),
      caption: 'Caption Text',
    },
    {
      src: toAbsoluteUrl('/media/landingpage/image (2).png'),
      caption: 'Caption Two',
    },
    {
      src: toAbsoluteUrl('/media/landingpage/pexelsgustavofring4254158-1@2x.png'),
      caption: 'Caption Three',
    },
  ]
  const [email, setEmail] = useState('')
  const [nom, setNom] = useState('')
  const [question, setQuestion] = useState('')

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
  const handleNomChange = (e: React.ChangeEvent<HTMLInputElement>) => setNom(e.target.value)
  const handleQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setQuestion(e.target.value)

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const response = await createOne({email: email, name: nom, message: question}, '/contact').then(
      (response) => {
        if (response === 2021) {
          toast.success(
            intl.formatMessage({
              id: 'ADMIN.COMMON.SUCCESS.CONTACT_ADDED_SUCCESSFULLY',
            }),
            {
              position: 'top-right',
              autoClose: 4000,
            }
          )
          setEmail('')
          setNom('')
          setQuestion('')
        } else {
          toast.error(
            intl.formatMessage({
              id: 'ADMIN.COMMON.ERROR.ERROR_CONTACT_ADDED_SUCCESSFULLY',
            }),
            {
              position: 'top-right',
              autoClose: 4000,
            }
          )
        }
        setEmail('')
        setNom('')
        setQuestion('')
      }
    )
  }
  return (
    <>
      {/* <head>
        <meta name='viewport' content='initial-scale=1, width=device-width' />

        <link rel='stylesheet' href='./global.css' />
        <link rel='stylesheet' href='./index.css' />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap'
        />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap'
        />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap'
        />
      </head> */}
      <head>
        <meta name='viewport' content='initial-scale=1, width=device-width' />

        <link rel='stylesheet' href='./global.css' />
        <link rel='stylesheet' href='./index.css' />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap'
        />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap'
        />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap'
        />
      </head>
      <ToastContainer />

      <div className='home-5'>
        <main>
          <section className='frame-group'>
            <div className='frame-container'>
              <div className='accueil-parent'>
                <div className='accueil'>
                  <a href='#accueil' style={{color: 'white', marginLeft: '5em'}}>
                    Accueil{' '}
                  </a>
                </div>
                <div className='notre-mission'>
                  <a href='#xx' style={{color: 'white'}}>
                    Notre mission
                  </a>
                </div>
                <div className='notre-missionn'>
                  <a href='#notre-mission' style={{color: 'white'}}>
                    Notre proposition{' '}
                  </a>
                </div>
                <div className='notre-mission'>
                  {' '}
                  <a href='/auth' className='a-propos' style={{color: 'white'}}>
                    {' '}
                    Se connecter{' '}
                  </a>
                </div>
              </div>

              <div className='logo-blanc300x-1-parent'>
                <img
                  className='logo-blanc300x-1'
                  loading='lazy'
                  alt=''
                  src={toAbsoluteUrl('/media/landingpage/logo@300x 1.png')}
                />
              </div>
            </div>
          </section>

          {/* <section className="slider">
  <div className="hero-slider">
    <div className="single-slider" style={{ backgroundImage: `url(${toAbsoluteUrl('/media/landingpage/slider.jpg')})` }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="text">
              <h1>We Provide <span>Medical</span> Services That You Can <span>Trust!</span></h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed nisl pellentesque, faucibus libero eu, gravida quam. </p>
              <div className="button">
                <a href="#" className="btn">Get Appointment</a>
                <a href="#" className="btn primary">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}

          <section className='frame-section' style={{marginTop: '35%'}}>
            <div className='frame-parent2'>
              <div className='frame-wrapper1'>
                <div className='background-parent'>
                  <div className='background'>
                    <div className='background-child'></div>
                    <div className='icon-wrapper'>
                      <img
                        className='icon1'
                        loading='lazy'
                        alt=''
                        src={toAbsoluteUrl('/media/landingpage/icon.svg')}
                      />
                    </div>
                    <div className='fournir-lespace-et'>
                      Fournir l’espace et l’expertise nécessaires aus industries, PME et grands
                      commerces
                    </div>
                  </div>
                  <div className='background1' id='xx'>
                    <div className='background-item'></div>
                    <div className='icon-container'>
                      <img
                        className='icon2'
                        alt=''
                        src={toAbsoluteUrl('/media/landingpage/icon-1.svg')}
                      />
                    </div>
                    <div className='rduire-significativement-leur'>
                      Réduire significativement leurs coûts énergétiques tout en préservant notre
                      planète.
                    </div>
                  </div>
                </div>
              </div>
              <div className='frame-parent3'>
                <div className='frame-wrapper2'>
                  <div className='frame-parent4'>
                    <div className='frame-parent5'>
                      <div className='frame-wrapper3'>
                        <div className='ellipse-group'>
                          <div className='frame-inner'></div>
                          <div className='ellipse-container'>
                            <div className='ellipse-div'></div>
                            <img
                              className='save-money-1-icon'
                              loading='lazy'
                              alt=''
                              src={toAbsoluteUrl('/media/landingpage/savemoney-1.svg')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='prise-en-charge-complte-de-wrapper'>
                        <div className='prise-en-charge'>
                          Prise en charge complète : de l’étude à la maintenance.
                        </div>
                      </div>
                    </div>
                    <div className='data-aggregator'>
                      <div className='nos-services-parent'>
                        <b className='nos-services'>Nos services </b>
                        <div className='input-selector'></div>
                      </div>
                      <h1 id='notre-mission' className='notre-proposition-unique'>
                        Notre proposition unique
                      </h1>
                    </div>
                    <div className='frame-parent6'>
                      <div className='frame-wrapper4'>
                        <div className='ellipse-parent1'>
                          <div className='frame-child1'></div>
                          <div className='ellipse-parent2'>
                            <div className='frame-child2'></div>
                            <img
                              className='solar-panels-1-icon'
                              loading='lazy'
                              alt=''
                              src={toAbsoluteUrl('/media/landingpage/solar-panels 1.png')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='espace-ddi-dans-des-zones-wrapper'>
                        <div className='espace-ddi-dans'>
                          Espace dédié dans des zones à haut rendement solaire.
                        </div>
                      </div>
                    </div>
                    <div className='frame-parent7'>
                      <div className='frame-wrapper5'>
                        <div className='ellipse-parent3'>
                          <div className='frame-child3'></div>
                          <div className='ellipse-parent4'>
                            <div className='frame-child4'></div>
                            <img
                              className='frame-icon'
                              loading='lazy'
                              alt=''
                              src={toAbsoluteUrl('/media/landingpage/frame.svg')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='suivi-performant-via-plateform-wrapper'>
                        <div className='suivi-performant-via'>
                          Suivi performant via plateforme et application mobile
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className='mask-group-icon'
                  loading='lazy'
                  alt=''
                  src={toAbsoluteUrl('/media/landingpage/mask-group@2x.png')}
                />
              </div>
              <div className='function-tree-wrapper'>
                <div className='function-tree'>
                  <div className='layout-manager'>
                    <div className='alignment-system'>
                      <div className='shape-collection'>
                        <b className='avantages-et-proposition'>
                          Avantages et proposition de valeur
                        </b>
                      </div>
                      <div className='size-scale'>
                        <div className='size-scale-child'></div>
                      </div>
                      <h1 className='pourquoi-choisir-gso'>Pourquoi choisir GSO ?</h1>
                    </div>
                  </div>
                  <div className='image-holder-parent'>
                    <div className='image-holder'>
                      <div className='slider-controller'>
                        <div className='background2'>
                          <div className='background-inner'></div>
                          <div className='button-bank'>
                            <div className='button-bank-inner'>
                              <div className='rectangle-parent'>
                                <div className='rectangle-div'></div>
                                <b className='b'>1</b>
                              </div>
                            </div>
                            <div className='optimisez-vos-dpenses'>
                              Optimisez vos dépenses et votre position sur le marché avec notre
                              solution solaire clé en main.
                            </div>
                          </div>
                          <div className='star-shape'>
                            <img
                              className='appointment-1-icon'
                              loading='lazy'
                              alt=''
                              src={toAbsoluteUrl('/media/landingpage/appointment-1.svg')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='rectangle-group'>
                        <div className='frame-child5'></div>
                        <b className='rduction-des-cots'>Réduction des coûts </b>
                      </div>
                    </div>
                    <div className='frame-wrapper6'>
                      <div className='frame-parent8'>
                        <div className='background-wrapper'>
                          <div className='background3'>
                            <div className='background-child1'></div>
                            <div className='raster-image'>
                              <div className='raster-image-inner'>
                                <div className='rectangle-container'>
                                  <div className='frame-child6'></div>
                                  <b className='audio-component'>2</b>
                                </div>
                              </div>
                              <div className='participez-la'>
                                Participez à la décarbonation et à la lutte contre le changement
                                climatique grâce à l’énergie solaire.
                              </div>
                            </div>
                            <div className='input-field'>
                              <img
                                className='house-2-1'
                                loading='lazy'
                                alt=''
                                src={toAbsoluteUrl('/media/landingpage/house-2-1.svg')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='dropdown-menu'>
                          <div className='dropdown-menu-child'></div>
                          <b className='engagement-actif-pour-container'>
                            <p className='engagement-actif'>Engagement actif</p>
                            <p className='pour-la-dcarbonation'>pour la décarbonation</p>
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className='image-holder1'>
                      <div className='background-container'>
                        <div className='background4'>
                          <div className='background-child2'></div>
                          <div className='frame-parent9'>
                            <div className='frame-wrapper7'>
                              <div className='group-div'>
                                <div className='frame-child7'></div>
                                <b className='b1'>3</b>
                              </div>
                            </div>
                            <div className='assurez-votre-autonomie'>
                              Assurez votre autonomie énergitique avec nos sites à haut rendement
                              solaire, pour une sécurité durable.
                            </div>
                          </div>
                          <div className='hired-1-1-wrapper'>
                            <img
                              className='hired-1-1'
                              loading='lazy'
                              alt=''
                              src={toAbsoluteUrl('/media/landingpage/hired-1-1.svg')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='rectangle-parent1'>
                        <div className='frame-child8'></div>
                        <b className='accs-lindpendance'>Accès à l’indépendance énergétiques</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-100'>
                {' '}
                <KTCard>
                  <EngageWidget10 className='h-md-100' />
                </KTCard>
              </div>
              <div className='popover-window'>
                <div className='tool-tip'>
                  <img
                    className='image-icon'
                    loading='lazy'
                    alt=''
                    src={toAbsoluteUrl('/media/landingpage/image@2x.png')}
                  />

                  <div className='tabs'>
                    <div className='stepper'>
                      <div className='carousel'>
                        <div className='gallery'>
                          <b className='engagement'>Engagement</b>
                          <div className='chart-component'></div>
                        </div>
                        <h1 className='avec-gso'>Avec GSO</h1>
                        <h3 className='soutenu-par-des-container'>
                          <p className='soutenu-par-des'>
                            Soutenu par des programmes internationaux
                          </p>
                          <p className='et-en-ligne'>
                            et en ligne avec les objectifs du plan solaire Tunisien, GSO vise à
                            réduire les émissions mondiales de gaz à effet de serre.
                          </p>
                        </h3>
                      </div>
                      <div className='shape-collection1'>
                        <div className='frame-parent10'>
                          <div className='image-array-parent'>
                            <div className='image-array'></div>
                            <div className='icon-cluster-parent'>
                              <div className='icon-cluster'></div>
                              <img
                                className='save-time-1-icon'
                                loading='lazy'
                                alt=''
                                src={toAbsoluteUrl('/media/landingpage/savetime-1.svg')}
                              />
                            </div>
                          </div>
                          <div className='oval-gallery'>
                            <div className='amliorez-votre-gestion'>
                              Améliorez votre gestion budgétaire et renforcez votre position
                              concurrentielle grâce à notre solution solaire tout-en-un.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='arcs-assembly'>
                <div className='contact-info-parent'>
                  <div className='contact-info'>
                    <div className='wrapper'>
                      <div className='info'>
                        <div className='text'>
                          <h2 className='slaheddine-el-guetari'>Slaheddine El Guetari</h2>
                          <div className='slaheddineelguetarigmailcom'>
                            slaheddine.elguetari@gmail.com
                          </div>
                        </div>
                      </div>
                      <div className='info1'>
                        <button className='mdishovel'>
                          <img
                            className='phone-icon'
                            alt=''
                            src={toAbsoluteUrl('/media/landingpage/phone.svg')}
                          />
                        </button>
                        <div className='decagon-dwell'>+216 98 531 626</div>
                      </div>
                      <div className='info2'>
                        <button className='mdishovel1'>
                          <img
                            className='map-pin-icon'
                            alt=''
                            src={toAbsoluteUrl('/media/landingpage/mappin.svg')}
                          />
                        </button>
                        <div className='rue'>Rue ............</div>
                      </div>
                    </div>
                  </div>
                  <div className='gradient-grid'>
                    <div className='opacity-options'>
                      <img
                        className='image-icon1'
                        alt=''
                        src={toAbsoluteUrl('/media/landingpage/image-1@2x.png')}
                      />

                      <div className='color-cluster'>
                        <div className='color-cluster-child'></div>
                        <h1 className='contactez-nous'>Contactez-nous</h1>
                        <div className='vous-souhaitez-nous-contacter-parent'>
                          <div className='vous-souhaitez-nous'>
                            Vous souhaitez nous contacter pour une demande d'information ou nous
                            faire part de vos remarques...
                          </div>
                          <div className='symbol-swatch'>
                            <div className='shape-shadowing'>
                              <input
                                className='input-box'
                                placeholder='Nom'
                                type='text'
                                value={nom}
                                onChange={handleNomChange}
                              />

                              <input
                                className='input-box'
                                placeholder='Email'
                                type='email'
                                value={email}
                                onChange={handleEmailChange}
                              />
                            </div>
                            <textarea
                              className='input-box2'
                              placeholder='Question'
                              value={question}
                              onChange={handleQuestionChange}
                            ></textarea>{' '}
                          </div>
                        </div>
                        <button className='button1' onClick={handleSubmit}>
                          <div className='icon-infographic'>
                            <div className='envoyer'>Envoyer</div>
                          </div>
                          <div className='arrow'>
                            <img
                              className='arrow-right-icon'
                              alt=''
                              src={toAbsoluteUrl('/media/landingpage/ic-arrow.svg')}
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <div
          className='pexels-gustavo-fring-4254158-1-icon'
          style={{height: 'unset', overflow: 'hidden', maxWidth: '100%'}}
        >
          <Carousel autoplay arrows={true} infinite={true}>
            {images.map((image, index) => (
              <div>
                <h3 style={contentStyle}>
                  <img alt='slider' src={image.src} style={{height: '70rem', width: '100%'}} />
                </h3>
              </div>
            ))}
          </Carousel>
        </div>

        <h1 className='notre-mission1'>Notre mission</h1>
        <footer className='background5'>
          <div className='background-child3'></div>
          {/* <div className='logo-blanc300x-1-wrapper'>
            <img
              className='logo-blanc300x-11'
              alt=''
              src={toAbsoluteUrl('/media/landingpage/logo-blanc300x-1@2x.png')}
            />
          </div> */}
          <div className='prt-faire'>
            Prêt à faire un pas vers un avenir plus durable? que vous soyez un professionnel à la
            recherche d’une solution énergétique économique, GSO est votre partenaire idéal.
          </div>
          <div className='triangle-trio-plus'>
            <div className='heptagon-hive-plus'>
              <div className='octagon-orchard-plus'>
                <img
                  className='icon3'
                  alt=''
                  src={toAbsoluteUrl('/media/landingpage/icon-2.svg')}
                />

                <div className='condition-branch'>
                  <div className='input-array'>+216 98 531 626</div>
                </div>
              </div>
              <div className='octagon-orchard-plus1'>
                <img
                  className='icon4'
                  alt=''
                  src={toAbsoluteUrl('/media/landingpage//icon-3.svg')}
                />

                <div className='slaheddineelguetarigmailcom-wrapper'>
                  <div className='slaheddineelguetarigmailcom1'>slaheddine.elguetari@gmail.com</div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}
export {Landing}
